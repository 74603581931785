import React, { useEffect, useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"
import PopuVideo from "../components/PopuVideo"

export const pageQuery = graphql`
 query {
    allStrapiTechnology {
        edges {
            node {
                seo {
                    metaTitle
                    metaDescription
                    shareImage {
                      url
                    }
                },
                video {
                  link
                  background {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 3840, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                      }
                    }
                  }
                },
                technology_types {
                    title
                    description
                    url
                    seo {
                        shareImage {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(width: 2368, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                          }
                        }
                      }
                }
            }
          }
    }
 }
`

export default function Tech({location,data}) {
    const [headerColor,setHeaderColor] = useState("bg-transparent text-white")
    const node = data.allStrapiTechnology.edges[0].node
    

    return (
      
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="relative h-screen w-screen overflow-hidden">
                <PopuVideo video={node.video.link} bg={node.video.background.localFile}/>
            </div>
            <div className="pb-[140px] overflow-hidden sm:pb-20" data-scroll="out">
                    {
                        node.technology_types.map((n,i)=>{
                            return <div key={i} className={`container mx-auto mt-[100px] xl:mt-10 min-h-[889px] xl:min-h-full relative flex xl:flex-col ${i%2!==0?'flex-row-reverse xl:flex-row-reverse':''}`}>
                                        <div className={`w-[430px] xl:w-full ${i%2!==0?"ml-auto":"mr-auto"}`}>
                                            <h2 className="text-[40px] leading-snug pb-5">{n.title}</h2>
                                            <p className="pb-10">{n.description}</p>
                                            <Button href={n.url} variant="black">了解更多</Button>
                                        </div>
                                        <GatsbyImage className={`w-[1184px] xl:w-full xl:!relative xl:mt-10 !absolute top-[0] ${i%2!==0?"right-[496px] xl:right-0":"left-[496px] xl:left-0"}`} image={getImage(n.seo.shareImage.localFile)} alt="product"/>
                                    </div>
                        })
                    }
            </div>
        </Layout>
    )
}

